import Swal from 'sweetalert2';
import util from '../util';

/**
 * Load the list of microservices.
 *
 * @param {Object} context Store context
 */
export default async (context) => {
  const { getters, commit } = context;
  const { isAuthenticated, isAutoRefreshing, region, environment } = getters;
  const { fetchMicroservicesEndpoint, fetchToken, validateStatus } = util;

  const endpoint = fetchMicroservicesEndpoint(region, environment);
  const accessToken = fetchToken(region);

  commit('microservicesLoading');

  // If the current user is not authorized
  if (!isAuthenticated) {
    return;
  }

  // Reset options only when refreshing manually
  if (!isAutoRefreshing) {
    commit('resetMicroservices');
  }

  try {
    const { status, data } = await axios({
      method: 'get',
      url: `${endpoint}`,
      validateStatus,
      headers: {
        Authorization: accessToken,
      },
    });

    if (status === 401 || status === 403) {
      return;
    }

    // If something went wrong with the request
    if (!data.ok) {
      Swal.fire('Error', data.message, 'error');
      return;
    }

    let microservices = data.stacks;

    if (region === 'eu-west-1') {
      microservices = microservices.filter((element) => {
        return element.StackId.includes('eu-west-1');
      });
    } else if (region === 'eu-west-2') {
      microservices = microservices.filter((element) => {
        return element.StackId.includes('eu-west-2');
      });
    } else if (region === 'us-east-1') {
      microservices = microservices.filter((element) => {
        return element.StackId.includes('us-east-1');
      });
    }

    if (environment === 'staging') {
      microservices = microservices.filter((element) => {
        return element.StackName.includes('staging');
      });
    } else if (environment === 'demo') {
      microservices = microservices.filter((element) => {
        return element.StackName.includes('demo');
      });
    } else if (environment === 'prod') {
      microservices = microservices.filter((element) => {
        return (
          element.StackName.includes('prod') ||
          element.StackName.includes('Prod')
        );
      });
    }

    commit('setMicroservices', microservices);
  } catch (error) {
    console.error(error.message);
    Swal.fire(
      'Error',
      'Unable to load microservices. Contact the administrator.',
      'error'
    );
  }
};
